import React from 'react';
import { Box, Button, Flex, Heading, Text } from 'rebass/styled-components';
import Link from 'gatsby-link';
import Layout from '../components/Layout';

const PrivacyPolicy = () => {
  return (
    <Layout>
      <Flex
        flexDirection={'column'}
        variant={'wrapper'}
        marginTop={[20, 40]}
        color={'text'}
      >
        <Box variant={'borderBox'}>
          <Box padding={[30, 60]}>
            <Heading
              marginLeft={20}
              mb={[25, 50]}
              fontSize={[24, 32]}
              fontFamily={'Oswald'}
            >
              Polityka prywatności
            </Heading>
            <Text
              margin={20}
              fontSize={[16, 20]}
              fontFamily={'OpenSans'}
              lineHeight={2}
            >
              <strong>Szanowni Państwo,</strong> <br />W związku z obowiązkiem
              zapewnienia bezpieczeństwa Państwa danych prosimy o zapoznanie się
              z poniższą informacją. Niniejsza informacja określa zasady
              przetwarzania danych osobowych pozyskanych za pośrednictwem
              serwisu internetowego&nbsp;
              <a href="http://www.prolegis.com.pl">www.prolegis.com.pl</a>
              <br />
              <br />
              <strong>
                Polityka prywatności i przetwarzania danych osobowych
              </strong>
              <br />
              przez Ośrodek Doskonalenia Nauczycieli „PROLEGIS” Elżbieta
              Linowska, ul. al. Jana Pawła II 59/43, 15-704 Białystok, placówkę
              oświatową wpisaną do Rejestru Szkół  i Placówek Oświatowych pod
              numerem RSPO 276980.
              <br />
              <ol>
                <li>
                  Administratorem Pani/Pana danych osobowych jest Ośrodek
                  Doskonalenia Nauczycieli „PROLEGIS” Elżbieta Linowska w
                  Białymstoku z siedzibą przy ul. al. Jana Pawła II 59/43,
                  15-704 Białystok; adres e-mail: biuro@prolegis.com.pl ; tel.
                  666025333.
                </li>
                <li>
                  Administrator nie powołał Inspektora Ochrony Danych –
                  wszelkich informacji odnośnie przetwarzania danych udziela ODN
                  „PROLEGIS” Elżbieta Linowska. W celu uzyskania pełniejszej
                  informacji o przetwarzaniu Pani/Pana danych osobowych należy
                  skontaktować się z Ośrodkiem Doskonalenia Nauczycieli
                  „PROLEGIS” Elżbieta Linowska; mail;
                  <a href="mailto:biuro@prolegis.com.pl">
                    biuro@prolegis.com.pl
                  </a>
                  ; ul. al. Jana Pawła II 59/43, 15-704 Białystok; tel.
                  666025333.
                </li>
                <li>
                  Pani/Pana dane osobowe są przetwarzane na podstawie przepisów
                  prawa: Rozporządzenie Ministra Edukacji Narodowej z dnia 28
                  maja 2019 r. w sprawie placówek doskonalenia nauczycieli (Dz.
                  U. poz. 1045).
                </li>
                <li>
                  ODN „PROLEGIS” Elżbieta Linowska w celu przeprowadzenia
                  naboru, organizacji i realizacji szkolenia, innych projektów
                  edukacyjnych, wydania zaświadczenia, prowadzenia dokumentacji
                  szkoleniowej oraz w celach archiwizacyjnych przetwarza
                  następujące Pani/Pana dane: imię/imiona, nazwisko, data i
                  miejsce urodzenia, telefon kontaktowy, e-mail, dane
                  szkoły/instytucji w postaci: nazwy, adresu i typu,
                  miejscowości, gminy, powiatu i telefonu. Podanie powyższych
                  danych jest wymagane przy rejestracji na szkolenie. Dodatkowo
                  w bazie mogą się znaleźć Pani/Pan dane do wystawienia faktury,
                  jeśli zostały wcześniej podane.
                </li>
                <li>
                  Podstawą prawną przetwarzania podanych danych osobowych jest
                  udzielona przez Pana/Panią zgoda (art. 6 ust. 1 lit. a RODO).
                  Wyrażenie tej zgody jest dobrowolne. Przysługuje Pani/Panu
                  prawo do cofnięcia zgody w dowolnym momencie z tym, że
                  wycofanie zgody nie będzie miało wpływu na zgodność z prawem
                  przetwarzania na podstawie zgody przed jej wycofaniem.
                  Cofnięcie zgody jest możliwe za pomocą: e-maila:
                  <a href="mailto:biuro@prolegis.com.pl">
                    biuro@prolegis.com.pl
                  </a>
                </li>
                <li>
                  Podanie danych osobowych jest warunkiem umownym, ale ich
                  niepodanie skutkuje brakiem możliwości realizacji celów, dla
                  których są gromadzone.
                </li>
                <li>
                  Odbiorcami Pani/Pana danych osobowych będą podmioty
                  uczestniczące w realizacji zamówienia:
                  <br />
                  <ul>
                    <li>
                      ECRK Lex s.c., odpowiedzialny za obsługę administracyjną
                      Ośrodka;
                    </li>
                    <li>
                      podmioty współpracujące z Administratorem – biura
                      rachunkowe, firmy informatyczne itp. nie mają dostępu do
                      żadnych danych osobowych uczestników i nie uczestniczą w
                      procesach ich przetwarzania
                    </li>
                  </ul>
                </li>
                <li>
                  Pani/Pana dane osobowe przechowywane będą przez okres 6 lat.
                </li>
                <li>
                  Uprawnienia osób, których dane są przetwarzane.
                  <br /> Posiada Pani/Pan:
                  <br />
                  <ol>
                    <li>prawo żądania sprostowania danych;</li>
                    <li> prawo do usunięcia danych;</li>
                    <li>prawo do ograniczenia przetwarzania;</li>
                    <li>
                      prawo do wyrażenia sprzeciwu wobec przetwarzania danych z
                      przyczyn związanych z Pana/Pani szczególną sytuacją,
                      zgodnie z art. 21 RODO;
                    </li>
                    <li>
                      w zakresie, w jakim podstawą przetwarzania Pana/Pani
                      danych osobowych jest zgoda wyrażona na podstawie
                      przepisów RODO – prawo do wycofania takiej zgody.
                      Wycofanie zgody nie ma wpływu na przetwarzanie, którego
                      dokonano przed jej wycofaniem;
                    </li>
                    <li>
                      prawo wniesienia skargi do Prezesa Urzędu Ochrony Danych
                      Osobowych  (ul. Stawki 2, 00-193 Warszawa), gdy uzna
                      Pan/Pani, że przetwarzanie Pana/Pani danych osobowych
                      narusza przepisy RODO.
                    </li>
                  </ol>
                </li>
                <li>
                  Pani/Pana dane osobowe nie są przetwarzane w sposób
                  zautomatyzowany oraz nie będą poddawane profilowaniu.
                </li>
                <li>
                  Mając na względzie fakt, iż jedyną drogą, jaką uczestnik
                  przekazuje dane osobowe jest droga elektroniczna (rejestracja
                  na Platformie, poczta email), Administrator zapewnia wszelką
                  możliwą ochronę techniczną tych danych, zapewniając
                  Uczestnikowi pełny  i nieograniczony wgląd w udostępnione
                  Administratorowi dane osobowe oraz inne prawa, jakie Uczestnik
                  posiada w związku z przetwarzaniem przez Administratora jego
                  danych osobowych. Administrator prowadzi na bieżąco analizę
                  ryzyka i monitoruje adekwatność stosowanych systemów
                  zabezpieczeń danych osobowych. W razie konieczności
                  Administrator wdraża dodatkowe środki i rozwiązania, służące
                  zwiększeniu zakresu bezpieczeństwa danych osobowych
                </li>
                <li>
                  Przysługuje Pani/Panu prawo wniesienia skargi do organu
                  nadzorczego – Prezesa Urzędu Ochrony Danych Osobowych, w
                  przypadku, gdy przetwarzanie Pana/Pani danych osobowych
                  narusza przepisy dotyczące ochrony danych osobowych.
                </li>
                <li>
                  Administrator zastrzega prawo do zmiany niniejszej polityki, w
                  szczególności w razie zmiany przepisów regulujących zasady
                  przetwarzania danych osobowych.
                </li>
              </ol>
              <Text textAlign={'right'}>
                W imieniu administratora danych,
                <br /> Elżbieta Linowska
              </Text>
            </Text>
            <a href={'polityka_prywatnosci_full_compressed.pdf'} download>
              <Button>Pełna wersja</Button>
            </a>
          </Box>
        </Box>
      </Flex>
    </Layout>
  );
};

export default PrivacyPolicy;
